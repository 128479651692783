import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["regions"]

  changeYear(event) {
    const year = event.target.value || "0"
    fetch(`/program_data/${year}/region_options`)
      .then(response => response.text())
      .then(html => this.regionsTarget.innerHTML = html)
  }
}

import Base from './base'
import { donut } from 'billboard.js'
import * as d3 from 'd3'

export default class extends Base {
  constructor(props) {
    super(props)
  }

  _render() {
    super._render()
    if (this.props.options.showLegend)
      this.addLegend()
  }

  dataHasValues() {
    return this.props.data.length
  }

  columns() {
    // If all values are null create donut that's gray
    const { data } = this.props
    if (!data || !data.length) {
      this.colors = ['#f9f9f9']
      return [ ['Data not available', 100] ]
    }

    if ('percent' in data[0] && 'name' in data[0]) {
      return data.map( ({name, percent} ) => [ name, +percent ] )
    } else {
      return data
    }
  }

  _options() {
    super._options()
    const opts = this.props.options
    const self = this
    const _options = {
      data: {
        columns: this.columns(),
        type: donut(),
        order: opts.order || null
      },
      donut: {
        title: '',
        label: {
          show: false
        }
      },
      color: {
        pattern: this.colors
      },
      size: {
        height: ( 'size' in opts && opts.size.height ) ? opts.size.height : 85,
        width: ( 'size' in opts && opts.size.width ) ? opts.size.width : 85
      },
      padding: {top: 0, bottom: 0, left: 0, right: 0},
      legend: { show: false },
      tooltip: { contents: this._tooltip.bind(this) },
      bindto: this.props.el,
    }
    if ('preventMouseEvents' in opts) {
      _options.onrendered = function () {
        this.main.selectAll('.bb-chart-arcs path')
          .attr('style', 'pointer-events: none')
          .on('mouseover', null)
          .on('mouseout', null)
          .on('mousemove', null)

      }
    }
    const label = document.querySelector(self.props.el).nextElementSibling
    if (label)
      label.classList.remove('d-none')
    if ('padding' in opts) {
      _options.padding = opts.padding
    }
    return _options
  }

  addLegend() {
    let items = this.props.data
    const { inlineLegend } = this.props.options
    if (!this.dataHasValues()) {
      items = [{name: 'Data not available', percent: null, count: null}]
    }
    d3.select(this.props.parentEl || this.props.el)
      .insert('div', '.chart')
      .attr('class', `c-chart-legend c-chart-legend--${inlineLegend ? 'inline' : 'stacked'}`)
      .selectAll('span')
      .data(items)
      .enter()
      .append('div')
      .attr('data-id', label => label.name)
      .html((data, i) => {
        const percent = data.percent !== null ? data.percent+'%' : ''
        const count = data.count !== null ? `(${data.count} ${this.props.options.countLabel})` : ''
        return (
          `
            <div class='c-chart-legend__swatch' style="background: ${this.colors[i]}"> </div>
            <div class='c-chart-legend__label'>
              <span class='d-block mb-1'>${ data.name }</span>
              <span class='c-metric d-inline'>${ percent }</span>
              <span class='d-inline'>${ count }</span>
            </div>
          `
        )
      })
      .each(function (id) {
        d3.select(this)
          .attr('class', `c-chart-legend__item c-chart-legend__item--swatch active`);
      })
      .on('mouseover', (label, id) => this.chart.focus(this.chart.data()[id].id))
      .on('mouseout', label => this.chart.revert())
      .on('click', (label, id, elements) => {
        const element = elements[label.id]
        const elementClasses = element.classList
        if (elementClasses.contains('active'))
          elementClasses.remove('active')
        else
          elementClasses.add('active')
        this.chart.toggle(this.chart.data()[id].id)
      })
  }

  getDataSet(setName='') {
    return this.props.data.find(s => s.name === setName)
  }

  _tooltip( data ) {
    const values = data.map((d, i) => {
      const set = this.getDataSet(d.name)
      let count = ''
      if (set && set.count !== null) {
        count = `<span class='d-inline'>(${set.count} ${this.props.options.countLabel})</span>`
      }
      return `
          <div class='c-tooltip-chart__value-wrapper'>
            <h3 class='c-tooltip-chart__title'>${d.name}</h3>
            <span class='c-tooltip-chart__value d-inline'>${d.value}%</span>
            ${count}
          </div>
        `
    })
    return `
      <div class='c-tooltip-chart'>
        ${values.join('')}
      </div>
    `
  }

}

import { importAll } from '../../javascript/utils'

// Base CSS (Sass)
// Import application stylesheets

// Elements
// (Redefined raw HTML element styles)
importAll(
  require.context(`../../stylesheets/base/elements`, true, /\.scss|\.sass|\.css/)
)

// Layouts
// Structural and skeletal
// (only to position main elements like header, footer, main)
importAll(
  require.context(`../../stylesheets/base/layouts`, true, /\.scss|\.sass|\.css/)
)

// Objects
// (High-level items with minimal styling)
importAll(
  require.context(`../../stylesheets/base/objects`, true, /\.scss|\.sass|\.css/)
)

// Components
importAll(
  require.context(`../../stylesheets/base/components`, true, /\.scss|\.sass|\.css/)
)

// Overrides
importAll(
  require.context(`../../stylesheets/base/overrides`, true, /\.scss|\.sass|\.css/)
)

// Utilities
// (Helper, utility and overide classes)
importAll(
  require.context(`../../stylesheets/base/utilities`, true, /\.scss|\.sass|\.css/)
)

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  open() {
    $(this.modalTarget).modal({ backdrop: "static", keyboard: false })
  }

  close() {
    $(this.modalTarget).modal('hide')
  }
}

import { Controller } from "stimulus"

// simple controller to add a confirmation modal to form submit
export default class extends Controller {
  connect() {
    if ( !this.data.has("disabled") || this.data.get("disabled") != "false" ) {

      this.element.submit( () => {
        return confirm(this.data.get("message"))
      })

    }
  }
}
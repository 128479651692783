import { initPopovers } from '../popovers'

export default class Timeline {

  constructor() {
    this.cardIds = []
    this.cardWrapperClass = 'c-timeline-card__wrapper'
    this.cardEditClass = 'c-timeline-card__edit'
    this.cardGroupClass = 'c-timeline-card-group'
    this.cachedCard = null
    this.registerEvents()
  }

  getCard(event) {
    return $(event.delegateTarget)
  }

  getCardParent($card) {
    return $card.closest(`.${this.cardGroupClass}`)
  }

  getCardWrapper($card) {
    return $card.find(`.${this.cardWrapperClass}`)
  }

  removeCard($card, attachments=true, delay=1000) {
    $card.addClass('removing')
    const $nextCard = $card.next()
    if ( $nextCard.hasClass('c-timeline-card--attachment') ) {
      this.removeCard($nextCard, false, 0)
    }
    setTimeout( () => { $card.remove() }, delay )
  }

  initContentPopovers($card) {
    initPopovers(`#${$card.prop('id')}`)
  }

  registerEvents() {
    $('.j-timeline-card').on('ajax:before', '.j-edit-comment', (e) => {
      this.cachedCard = this.getCardParent(this.getCard(e)).html()
    })

    $('.j-timeline-card').on('click', '.j-cancel-edit', (e) => {
      const $card = this.getCard(e)
      this.getCardParent($card).html($(this.cachedCard))
      this.initContentPopovers($card)
    })

    $('.j-timeline-card').on('ajax:success', '.j-edit-comment', (e) => {
      const $card = this.getCard(e)
      const $html = $(`<div class="${this.cardEditClass}"/>`)
      $html.append(e.originalEvent.detail[2].responseText)
      this.getCardWrapper($card)
        .html($html)
      this.initCardForm($card)
    })

    $('.j-timeline-card').on('ajax:success', '.j-delete-comment', (e) => {
      this.removeCard(this.getCard(e))
    })

    $('body').on('click', (event) => {
      const popoversOpen = $('.popover').length
      const $target = $(event.target)
      if (!$target.hasClass('c-inline-link') || popoversOpen) {
        $('.c-inline-link').popover('hide')
        if (popoversOpen) $target.popover('show')
      }
    })
  }

  initCardForm($card) {
    const $form = $card.find('form')
    $form.on('ajax:success', (e) => {
      const $newCardContent = $(e.originalEvent.detail[2].responseText)
      $newCardContent.addClass('edited')
      const $newCard = this.getCardParent($card).replaceWith($newCardContent)
      this.registerEvents()
      setTimeout(()=> {
        $(`.${this.cardGroupClass}.edited`).removeClass('edited')
      }, 2500)
      this.initContentPopovers($card)
    })
    $form.on('ajax:error', (e) => {
      const $html = $(`<div class="${this.cardEditClass}"/>`)
      $html.append(e.originalEvent.detail[2].responseText)
      $card.find(`.${this.cardWrapperClass}`).html($html)
      this.initCardForm($card)
      this.initContentPopovers($card)
    })
  }

}

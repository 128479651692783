import bb from 'billboard.js'
import { colors } from './options'

export default class {
  constructor(props) {
    this.props = props
    this.chart = null
    this.props.options = props.options || {}
    this.colors = this._getColors()
    this._render()
  }

  _options() {
    return {}
  }

  _getColors() {
    const { options } = this.props
    if (options.colors) {
      return options.colors
    } else if (options.binary) {
      return [colors()[0], '#EEEEEE']
    } else {
      return colors()
    }
  }

  _render() {
    this.chart = bb.generate(this._options())
  }

}

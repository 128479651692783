export default class Affix {
  /**
   * constructor
   *
   * @param  {Node} element
   * @param  {Number} offset   offset from top of page
   * @return NA
   */
  constructor({ element, containingElement, relatedElement, callback }) {
    this.stuck = false
    this.affixing = false
    this.element = element
    this.offset = null
    this.containingElement = containingElement
    // Element that needs to be taller than the fixed element
    this.relatedElement = relatedElement
    this.offset = $(this.containingElement).offset().top
    this.callback = callback
    this.spy()
  }

  /**
   * spy - Add scroll event listener to window to determine stuck state
   *
   * @return {}
   */
  spy() {
    window.addEventListener("scroll", (event) => {
      let needToAffix = true
      // Determine if the internal element of the affixed element
      // is taller/shorter than the related element's content
      // If it is taller do not affix because scroll issues
      // occur if we do.
      if (this.relatedElement) {
        let elementHeight = 0
        const wrapperEl = this.containingElement.querySelector('.wrapper')
        const relatedWrapperEl = this.relatedElement.querySelector('.wrapper')
        const wrapperHeight = (relatedWrapperEl || this.relatedElement).clientHeight
        if (wrapperEl)
          elementHeight = wrapperEl.clientHeight
        needToAffix = wrapperHeight && elementHeight < wrapperHeight
      }

      if (this.affixing || !needToAffix) return
      this.affixing = true
      // If on small screen hero image is not in view so set elementOffsetTop
      // to bottom of nav
      if (window.scrollY >= this.offset && !this.stuck) {
        this.affix(true)
      } else if (window.scrollY < this.offset && this.stuck) {
        this.affix(false)
      }
      this.affixing = false
    })
  }

  /**
   * affix - Add or remove classes which stick elements
   *
   * @param  {Boolean} stick Whether to stick or not
   * @return NA
   */
  affix(stick) {
    let classActionMethod = 'remove'

    if (stick) {
      classActionMethod = 'add'
    }

    this.stuck = stick
    document.querySelector('.l-page')
      .classList[classActionMethod]('with-affixed-elements')
    this.element
      .classList[classActionMethod]('affixed')
    if (this.callback)
      this.callback.call(this, stick)
  }
}

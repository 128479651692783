import { Controller } from "stimulus"
import Rails from "rails-ujs"

// Deprecated: Use app/webpack/controllers/simple_modal_controller.js
// (and a form within the modal) instead, to avoid unecessary complexity.
export default class extends Controller {
  static targets = ["confirmationModal", "confirmationButton"]

  confirm(event) {
    const eventTarget = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    const promise = new Promise( (resolve, reject) => {
      this.confirmationResolve = resolve
      this.confirmationReject = reject

      $(this.confirmationModalTarget).modal({backdrop: "static", keyboard: false})
    })

    promise.then( () => {
      this._navigate(eventTarget)
    }, () => {})
  }

  allowConfirmation(allow=false) {
    if (this.confirmationButtonTarget) {
      this.confirmationButtonTarget.disabled = !allow
    }
  }

  sign(event) {
    event.preventDefault()
    this.allowConfirmation(event.target.checked)
  }

  accept(event) {
    console.log("Confirmation Accepted")
    event.preventDefault()
    $(this.confirmationModalTarget).modal('hide')
    this.confirmationResolve()
  }

  reject(event) {
    console.log("Confirmation Rejected")
    event.preventDefault()
    $(this.confirmationModalTarget).modal('hide')
    this.confirmationReject()
  }

  _navigate(link) {
    console.log('Navigating!');
    const method = (link.dataset.method || "GET")
    const href = (link.href || link.dataset.href)

    const csrfToken = Rails.csrfToken();
    const csrfParam = Rails.csrfParam();
    const form = document.createElement('form');

    console.log('method, href', method, href);

    let formContent = `<input name='_method' value='${method}' type='hidden' />`;

    if ((csrfParam != null) && (csrfToken != null) && !Rails.isCrossDomain(href)) {
      formContent += `<input name='${csrfParam}' value='${csrfToken}' type='hidden' />`;
    }

    // Must trigger submit by click on a button, else "submit" event handler won't work!
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
    formContent += '<input type="submit" />';
    form.method = 'post';
    form.action = href;
    if (link.target) {
      form.target = link.target;
    }
    form.innerHTML = formContent;
    form.style.display = 'none';
    document.body.appendChild(form);

    form.querySelector('[type="submit"]').click();
  }


}

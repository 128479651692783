import { Controller } from "stimulus"
import Rails from "rails-ujs"

const ITEM_READ_CLASS = "c-notification-item--read"

// Handle actions performed on UserNotifications in the notifications dropdown.
// Error handling is omitted as failure is unlikely and relatively inconsequential.
export default class extends Controller {

  static targets = [ "count", "item" ]

  connect() {
    this.markAllReadUrl = this.data.get('markAllReadUrl')
  }

  markAllRead(e) {
    e.stopPropagation()
    this._post(this.markAllReadUrl)
      .then(response => response.json())
      .then((data) => {
        console.log(`Marked ${data?.updated} notifications as read`)
      })
    // Optimistically update the UI
    this.countTarget.classList.add("d-none")
    this.itemTargets.forEach((el) => {
      el.classList.add(ITEM_READ_CLASS)
    })
  }

  deleteNotification(e) {
    e.stopPropagation()
    this._delete(e.currentTarget.dataset.deleteUrl)
    // Optimistically update the UI
    const id = e.currentTarget.dataset.notificationId
    this.itemTargets.forEach((el) => {
      if (el.dataset.notificationId === id) {
        if (!el.classList.contains(ITEM_READ_CLASS)) {
          this._decrementCount()
        }
        el.classList.add("d-none")
      }
    })
  }

  _decrementCount() {
    const count = parseInt(this.countTarget.innerText)
    if (count === 1) {
      this.countTarget.classList.add("d-none")
    } else {
      this.countTarget.innerText = (count - 1).toString()
    }
  }

  _post(url, data = null) {
    return this._request(url, "POST", data)
  }

  _delete(url, data = null) {
    return this._request(url, "DELETE", data)
  }

  _request(url, method, data = null) {
    const formData = new FormData()
    if (data) {
      for (const [k, v] of Object.entries(data)) {
        formData.append(k, v)
      }
    }
    if (!Rails.isCrossDomain(url)) {
      formData.append(Rails.csrfParam(), Rails.csrfToken())
    }
    return fetch(url, { method: method, body: formData })
  }
}

import Donut from '../charts/donut'

App.charts = {
  Donut: Donut,
}

App.readyCallbacks = App.readyCallbacks || []

import '../timeline'

import Affix from '../affix'
App.Affix = Affix

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = []

  connect() {
    window.setTimeout( () => {this.checkStatus()}, 5000)
  }

  checkStatus() {
    $.getJSON(this.data.get("url"), (responseData) => {
      if (this.doneStatus.includes(responseData.status)) {
        window.location.reload()
      } else {
        window.setTimeout( () => {this.checkStatus()}, 5000)    
      }
    })
  }

  get doneStatus() {
    if (this.data.has("doneStatus")) {
      return this.data.get("doneStatus").split(/\s/)
    } else {
      return ["processing_complete", "error"]
    }
  }
}
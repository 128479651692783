export function initPopovers(container='body') {
  $(`${container} .j-popover`).each(function(){
    $(this).parent().popover({
      template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
      html: true,
      content: $(this).html(),
      placement: 'bottom'
    })
  })
}

import { Controller } from "stimulus"

/**
 * Used in app/views/certification_letters/new.haml
 *
 * A user must first select a fiscal year, which determines the list of
 * available assessments. Then they select an assessment, which determines
 * which licensees are available to select. Once a licensee is selected,
 * they are allowed to submit the form.
 */
export default class extends Controller {
  static targets = ["assessments", "licensees", "submitButton"]

  // Handle populating the list of available assessments by fiscal year
  changeFiscalYear(event) {
    this.submitButtonTarget.setAttribute("disabled", true)
    this.licenseesTarget.setAttribute("disabled", true)
    this.licenseesTarget.innerHTML = ""
    fetch(`/certification_letters/assessment_options?fiscal_year=${event.target.value}`)
      .then(response => response.json())
      .then(json => {
        this.assessmentsTarget.innerHTML = this.assessmentsHTML(json)
      })
  }

  // Handle populating the list of available licensees when an assessment is selected
  changeAssessment(event) {
    this.submitButtonTarget.setAttribute("disabled", true)
    fetch(`/certification_letters/assessment_licensees?assessment_id=${event.target.value}`)
      .then(response => response.json())
      .then(json => {
        this.licenseesTarget.innerHTML = this.licenseesHTML(json)
        this.licenseesTarget.removeAttribute("disabled")
      })
  }

  // Enable the submit button when a licensee has been selected
  changeLicensee(event) {
    if (event.target.value) {
      this.submitButtonTarget.removeAttribute("disabled")
    } else {
      this.submitButtonTarget.setAttribute("disabled", true)
    }
  }

  assessmentsHTML(json) {
    const options = json.map((data) => this.assessmentOption(data[0], data[1]))
    return `<option value label=" "></option>${options.join("")}`
  }

  licenseesHTML(json) {
    const options = json.map((data) => this.licenseeOption(data[0], data[1]))
    return `<option value label=" "></option>${options.join("")}`
  }

  assessmentOption(id, name) {
    return `<option value="${id}" label="${name}"></option>`
  }

  licenseeOption(name, email) {
    const label = email ? `${name} (${email})` : name
    return `<option value="${name}" label="${label}"></option>`
  }
}

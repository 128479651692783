import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"
import Rails from "rails-ujs"

// adapted from https://gorails.com/episodes/at-mentions-with-actiontext

export default class extends Controller {

  static targets = ['field']

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
    this.suggestUrl = this.data.get('suggestUrl')
    this.createReferenceUrl = this.data.get('createReferenceUrl')
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '#',
      lookup: 'store_number',
      values: this.fetchStores.bind(this),
      menuItemTemplate: item => `(${item.string}) ${item.original.name}`
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this))
  }

  replaced(e) {
    let store = e.detail.item.original
    // get the store id and url from e
    // then post to the url with the store_id as a param
    // build and insert the attachment with what we get back (the store reference)
    const formData = new FormData()
    formData.append("assessment_store_id", store.id)
    if (!Rails.isCrossDomain(this.createReferenceUrl)) {
      formData.append(Rails.csrfParam(), Rails.csrfToken())
    }
    fetch(this.createReferenceUrl, {method: "POST", body: formData})
    .then(response => response.json())
    .catch(error => console.error('Error:', error))
    .then(data => {
      console.log('data', data)
      let attachment = new Trix.Attachment({
        sgid: data.sgid,
        content: data.content
      })

      this.editor.insertAttachment(attachment)
      this.editor.insertString(" ")
    })
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos, position])
    this.editor.deleteInDirection("backward")
  }

  fetchStores(text, callback) {
    fetch(`${this.suggestUrl}?query=${text}`)
      .then( response => response.json() )
      .then( stores => callback(stores) )
      .catch( error => {
        console.error({ message: 'fetchStores failed', error })
        return callback([])
      })
  }
}

import './namespace'
import './api'
import '../events/ready'

import Rails from 'rails-ujs'
Rails.start();

import 'bootstrap/dist/js/bootstrap';

require("@rails/activestorage").start()

window.$ = $
window.jQuery = jQuery

import 'select2'
import autosize from 'autosize'

import { initPopovers } from '../popovers'

$(() => {
  $(".j-select2").select2()
  $('[data-toggle="tooltip"]').tooltip()
  initPopovers()
  autosize(document.getElementsByTagName('textarea'))
})

require("@rails/actiontext")

import { importAll } from "../javascript/utils"

/////////////////////////////
// Images Init
/////////////////////////////
importAll( require.context("../images", true, /.*/) )

////////////////////////////
// CSS Init
////////////////////////////
import "./styles/vendor"
import "./styles/base"

/////////////////////////////
// JS Init
/////////////////////////////
import "../javascript/index"
import "../controllers/index"

import { Controller } from "stimulus"

// simple controller to add a confirmation modal to form submit
export default class extends Controller {
  static SUBMIT_DELAY = 1500

  connect() {
    const form = this.element
    ;[...form.querySelectorAll('input[type=radio]')]
      .forEach((input) => {
        input.addEventListener("click", function(event) {
          const btnGroup = form.querySelector('.btn-group')
          if (btnGroup) {
            btnGroup.classList.add('loading')
            event.target.blur()
          }
          // Timeout blocks form submit to allow animation to occur
          // for better UX - gives user indication
          // animation timing is established on line 88 of
          // app/webpack/stylesheets/base/overrides/bootstrap/buttons.scss
          setTimeout( () => form.submit(), 1500 )
        })
      })
  }
}

import { Controller } from "stimulus"
import castArray from "lodash/castArray"

// Stimulus controller for a form field that changes disabled state depending on the state of the enclosing form
// specify at least one condition with
// <input ... data-enableable-form-field-condition="{<formInputName>: [ <values> ] }" />
// if any values are matched, the form field will be enabled

// TODO figure out how to update.  Maybe just a change handler on the form tho that's overkill

export default class extends Controller {
  connect() {
    this.form = $(this.element).parents("form")[0]
    this.conditions = JSON.parse(this.data.get("conditions"))

    $(this.form).on("change", () => this.refreshState())
    this.refreshState()
  }

  refreshState() {
    let enabled = true
    const formData = new FormData(this.form)

    Object.entries(this.conditions).forEach(condition => {
      const conditionName = condition[0]
      const conditionValues = castArray(condition[1])

      const formDataValues = formData.getAll(conditionName)

      const intersection = conditionValues.filter(v => formDataValues.includes(v));
      // console.log('intersection', intersection)

      if (intersection.length == 0) {
        enabled = false
      }
    })

    if (enabled) {
      $(this.element).removeClass("disabled")
      $(this.element).find("input, select, textarea").removeAttr("disabled")
    } else {
      $(this.element).addClass("disabled")
      $(this.element).find("input, select, textarea").attr("disabled", "disabled")
    }
  }

}

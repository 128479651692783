import { Controller } from "stimulus"

// simple controller to add a confirmation modal to form submit
export default class extends Controller {
  static targets = ['requiredInput', 'validationTrigger']

  connect() {
    this.isDirty = false
    this.formSubmitting = false
    const requiredInput = this.requiredInputTarget
    const dirtyForm = () => this.isDirty = true

    this.element.addEventListener('input', dirtyForm)
    // Add click event to radio buttons because change is not
    // triggered on form when radio changes
    const radioButtons = [...this.element.querySelectorAll('label.btn')]
    if (radioButtons.length) {
      radioButtons.forEach((radio) => {
        radio.addEventListener('click', dirtyForm)
      })
    }

    requiredInput.addEventListener('change', this.validate.bind(this))
    requiredInput.addEventListener('input', this.validate.bind(this))
    requiredInput.addEventListener('blur', (event) => {
      this.trimInputValue(event.target)
    })
    this.element.addEventListener('submit', this.submit.bind(this))

    // Show confirmation alert when navigating away from page and form is dirty
    window.onbeforeunload = (event) => {
      if (this.isDirty && !this.formSubmitting) {
        return 'Changes you made may not be saved.'
      }
    }
  }

  trimInputValue(input) {
    input.value = input.value.trim()
  }

  submit(event) {
    this.formSubmitting = true
    this.trimInputValue(this.requiredInputTarget)
  }

  validate() {
    const validationTrigger = this.validationTriggerTarget
    if (this.requiredInputTarget.value.trim().length) {
      validationTrigger.removeAttribute('disabled')
    } else {
      validationTrigger.querySelector('input').checked = false
      validationTrigger.setAttribute('disabled', true)
      validationTrigger.classList.remove('active')
    }
  }
}
